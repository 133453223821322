import { WorbImage } from "../worb-image/worb-image";
import { notNull } from "../../helpers/notNull";
import { WorbCarouselItem } from "../../__generated__/crepo-types";
import { useState } from "react";
import classNamesBind from "classnames/bind";
import classNames from "classnames";
import styles from "../worb-image/worb-image.module.css";

const cx = classNamesBind.bind(styles);

const TABLET_BREAKPOINT = 768;
const VIDEO_WIDTH_MOBILE = 540;
const VIDEO_WIDTH_DESKTOP = 960;

interface WorbVideoProps {
  item: WorbCarouselItem;
  viewportWidth: number;
  className: string;
  imageClassName: string;
  hasIntersected: boolean;
}

export const WorbVideo = ({
  item,
  viewportWidth,
  className,
  imageClassName,
  hasIntersected,
}: WorbVideoProps) => {
  const secondFeaturedMedia = item.featuredMedia?.[1];
  const videoItem =
    (item.type === "rrn:content:videos" ||
      item.type === "rrn:content:videos-360" ||
      item.type === "rrn:content:films" ||
      item.type === "rrn:content:movies") &&
    secondFeaturedMedia &&
    "clipEssence" in secondFeaturedMedia &&
    secondFeaturedMedia.clipEssence &&
    "renditions" in secondFeaturedMedia.clipEssence &&
    secondFeaturedMedia.clipEssence.renditions
      ?.filter(notNull)
      .find((rendition) =>
        viewportWidth < TABLET_BREAKPOINT
          ? rendition.ratioTextual === "9x16" &&
            rendition.width === VIDEO_WIDTH_MOBILE
          : rendition.ratioTextual === "16x9" &&
            rendition.width === VIDEO_WIDTH_DESKTOP,
      );

  const [isWorbImageHidden, setIsWorbImageHidden] = useState<boolean>(false);
  return videoItem && videoItem.clipURL ? (
    <div>
      {hasIntersected && (
        <video
          className={classNames(cx("container"), className)}
          preload="auto"
          autoPlay={true}
          playsInline={true}
          muted={true}
          loop={true}
          src={videoItem.clipURL}
          onCanPlay={() => setIsWorbImageHidden(true)}
        />
      )}
      <WorbImage
        item={item}
        hidden={isWorbImageHidden}
        className={imageClassName}
      />
    </div>
  ) : (
    <WorbImage
      item={item}
      hidden={isWorbImageHidden}
      className={imageClassName}
    />
  );
};
