"use client";

import { useLocaleStringContext } from "../../helpers/LocaleStringContext";
import classNamesBind from "classnames/bind";
import classNames from "classnames";
import { getRbImageUrl } from "../../helpers/images/getRbImageUrl";
import { WorbCarouselItem } from "../../__generated__/crepo-types";
import { notNull } from "../../helpers/notNull";
import { isImage } from "../../helpers/typeguards";
import { useImageLoading } from "../../helpers/ImageLoadingContext";
import styles from "./worb-image.module.css";

const cx = classNamesBind.bind(styles);

const PORTRAIT_RATIO = 1.7777777778;

interface WorbImageProps {
  item: WorbCarouselItem;
  className: string;
  hidden?: boolean;
}

export const WorbImage = ({ item, className, hidden }: WorbImageProps) => {
  const localeString = useLocaleStringContext();

  const firstFeaturedMedia = item.featuredMedia?.filter(notNull).find(isImage);

  const image =
    firstFeaturedMedia?.imageEssence?.__typename === "CloudinaryImage"
      ? firstFeaturedMedia.imageEssence.imageURL
      : firstFeaturedMedia?.imageSrc;

  const imageLoading = useImageLoading();

  return (
    <img
      className={classNames(
        className,
        cx("container", {
          "container--hidden": hidden,
        }),
      )}
      loading={imageLoading}
      srcSet={
        image
          ? getWidths(
              image,
              {
                portrait: [450, 600, 800],
                scale: [1200, 1600, 2000],
              },
              localeString,
            )
          : undefined
      }
      sizes="100vw"
    />
  );
};

interface Widths {
  scale: number[];
  portrait: number[];
}

function getWidths(imageUrl: string, widths: Widths, localeString: string) {
  const portraitWidths = `${
    widths.portrait
      ? widths.portrait.map(
          (width) =>
            `${getRbImageUrl(
              imageUrl,
              {
                width,
                height: Math.round(width * PORTRAIT_RATIO),
              },
              localeString,
            )} ${widths.portrait.length > 1 ? `${width}w` : ""}`,
        )
      : ""
  }${widths.portrait && widths.portrait.length > 1 ? ", " : ""}`;

  const scaleWidths = `${
    widths.scale
      ? widths.scale.map(
          (width) =>
            `${getRbImageUrl(imageUrl, { width }, localeString)} ${
              widths.scale.length > 1 ? `${width}w` : ""
            }`,
        )
      : ""
  }${widths.scale && widths.scale.length > 1 ? ", " : ""}`;

  return `${portraitWidths}${scaleWidths}`;
}
