function t(translation?: string, defaultValue?: string): string {
  if (translation) {
    return translation;
  }
  if (!translation && defaultValue) {
    return defaultValue;
  }
  return "No translation available";
}

export function translateWithPlaceholders(
  translation: string,
  replacements: Record<string, string>,
): string | null {
  const placeholderNames = Object.keys(replacements);
  if (translation) {
    return placeholderNames.reduce(
      (textWithPlaceholders, placeholderName) =>
        textWithPlaceholders.replace(
          `{{${placeholderName}}}`,
          replacements[placeholderName],
        ),
      translation,
    );
  }
  return null;
}

export default function template(
  translation?: string,
  fallback?: string,
  replacements?: Record<string, string>,
): string | null {
  const result = t(translation, fallback);
  if (replacements) {
    return translateWithPlaceholders(result, replacements);
  }
  return result;
}
